<template>
  <h1>Mes produits</h1>
  <div class="row">
    <div class="col-lg-12">
        <div class="ibox">
            <div class="ibox-title">                
                <router-link  :to="{ name: 'produit_import'}" class="btn btn-primary pull-right"><i class="fa fa-plus text-navy"></i> Importer des produits depuis Vinipedia</router-link>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6" v-for="produit in produits" :key="produit.id">
        <div class="card text-center">
            <div class="card-body">
                <h2>{{ produit.titre }}</h2>
                {{ produit.stock }} en stock - {{ produit.prix }} €
                <router-link  :to="{ name: 'produit', params: { uuid: produit.uuid }}" title="Modifier le produit"><i class="fa fa-edit fa-2x text-pink"></i></router-link>
            </div>
        </div>
    </div>
            
    
</div>
</template>

<script>


export default {
  name: 'Produits',
  data() {
    return {  
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {      
      this.$store.dispatch('getProduits');
    }
  },
  computed: {
    produits () {
      return this.$store.state.produits.produits
    },
  },
  components: {
  },
};
</script>